
import { defineComponent, ref, reactive, computed, Ref, watch, onMounted } from "vue";
import semverSort from "semver/functions/sort";
import { useStore } from "@/store/store";
import Panel from "../panel-general/Panel.vue";
import { ActionTypes } from "@/store/action-types";
import { MutationTypes } from "@/store/mutation-types";
// import HeaderNavbar from "./PlatformNavbar.vue";
import { PreviewArgument, Spread, View, ViewMap } from "@bubblydoo/common";
import { swapArrayElementsNew, removeFromArray } from "@/util/array";

export default defineComponent({
  props: ["pickedPreviewArgument", "project", "pkg", "currentViewKey", "currentSpread", "rendering", "live", "liveInterval"],
  components: {
    // HeaderNavbar,
    Panel,
  },
  setup(props, { emit }) {
    const store = useStore();

    const internalPickedPreviewArgument = ref<PreviewArgument | null>(props.pickedPreviewArgument);
    const internalLiveInterval = ref<number | null>(props.liveInterval);
    const displayView = ref(false);
    const displayPreviewSelector = ref(false);
    const lockPreview = ref(false);

    watch(computed(() => props.pickedPreviewArgument), () => {
      internalPickedPreviewArgument.value = props.pickedPreviewArgument;
    });

    watch(internalPickedPreviewArgument, () => {
      emit("update:pickedPreviewArgument", internalPickedPreviewArgument.value);
    });

    watch(computed(() => props.liveInterval), () => {
      internalLiveInterval.value = props.liveInterval;
    });

    watch(internalLiveInterval, () => {
      emit("update:liveInterval", internalLiveInterval.value);
    });

    const currentPackage = computed(() => store.getters.currentPackage);
    const currentProject = computed(() => store.getters.currentProject);
    const projects = computed(() => store.getters.projects);

    const currentProjectId: Ref<string | null> = ref(null);
    const currentPackageId: Ref<string | null> = ref(null);

    watch(currentProject, () => {
      emit("update:project", currentProject.value);
    });

    watch(currentPackage, () => {
      emit("update:pkg", currentPackage.value);
    });

    watch(currentProjectId, (projectId) => {
      const projects = store.getters.projects;
      if (!projects) return;
      if (projectId) {
        store.dispatch(ActionTypes.FETCH_PROJECT, projectId);
      } else {
        store.commit(MutationTypes.SET_CURRENT_PROJECT, null);
      }
    });

    watch(currentPackageId, (packageId) => {
      if (packageId) {
        store.dispatch(ActionTypes.FETCH_PACKAGE, packageId);
      } else {
        store.commit(MutationTypes.SET_CURRENT_PACKAGE, null);
      }
    });

    watch(currentProject, (project) => {
      currentProjectId.value = project ? project.id : null;
    });

    watch(currentPackage, (pkg) => {
      currentPackageId.value = pkg ? pkg.id : null;
      if (pkg && !currentView.value) {
        currentSpread.value = null;
      } else if (!pkg) {
        currentViewKey.value = null;
        currentSpread.value = null;
      }
    });

    const currentViewKey: Ref<string | null> = ref(props.currentViewKey);
    const currentView = computed(() =>
      currentPackage.value && currentViewKey.value
        ? currentPackage.value.data.views[currentViewKey.value]
        : null
    );

    watch(currentViewKey, (viewKey) => {
      emit("update:viewKey", viewKey);
      emit("update:view", currentView.value);
      currentSpread.value = null;
    });

    const currentSpread: Ref<Spread | null> = ref(props.currentSpread);

    watch(currentSpread, () => {
      emit("update:spread", currentSpread.value);
    });

    const fetchProjects = () => {
      store.dispatch(ActionTypes.FETCH_PROJECTS);
    };

    const rendering = computed(() => props.rendering)
    const live = computed(() => props.live)

    onMounted(() => fetchProjects());

    return {
      rendering,
      live,
      internalLiveInterval,
      internalPickedPreviewArgument,
      fetchProjects,
      projects,
      currentProject,
      currentPackage,
      currentView,
      currentViewKey,
      currentSpread,
      currentProjectId,
      currentPackageId,
      displayView,
      setDisplayView: (setValue: boolean) => (displayView.value = setValue),
   
      displayPreviewSelector,
      setDisplayPreviewSelector: (setValue: boolean) => (displayPreviewSelector.value = lockPreview.value || setValue),
      lockPreview,
      setLockPreview: (setValue: boolean) => {
        (lockPreview.value = setValue)
        displayPreviewSelector.value = setValue
      },
      sortedPackages: computed(() => {
        return [...currentProject.value!.packages!].sort((a, b) =>
          semverSort([a.version, b.version])[0] === a.version ? -1 : 1
        );
      }),
      deleteProject() {
        const projectName = currentProject.value?.name;
        const confirmation = prompt(`
          Are you sure you want to delete this project?
          Type the project name to confirm.
        `);
        if (confirmation !== projectName) return;
        alert(`lol you thought you could delete a project`);
      },
      play() {
        emit("play");
      },
      toggleLive() {
        emit("toggleLive");
      },
      async save() {
        const pkg = currentPackage.value!;
        const version = prompt(`Version (current version ${pkg!.version || "none"})`);
        if (!version) return;
        try {
          await store.dispatch(ActionTypes.CREATE_PACKAGE, { ...pkg, version });
        } catch (e) {
          alert(`Something went wrong while creating package: ${e}`);
        }
      },
      addSpread() {
        const spread = {
          name: "Spread",
          format: {
            width: 100,
            height: 100,
          },
          layers: [],
        };
        currentView.value!.spreads.push(spread);
        currentSpread.value = spread;
      },
      removeSpread(index: number) {
        if (!confirm('Remove spread?')) return;
        const s = currentView.value!.spreads;
        currentView.value!.spreads = removeFromArray(s, index);
      },
      upSpread(index: number) {
        currentView.value!.spreads = swapArrayElementsNew(currentView.value!.spreads, index, index + 1);
      },
      downSpread(index:number) {
        currentView.value!.spreads = swapArrayElementsNew(currentView.value!.spreads, index, index - 1);

      },
      addView() {
        const name = prompt("View name");
        if (!name) return;
        const viewMap: ViewMap = {
          ...currentPackage.value!.data.views,
          [name]: {
            spreads: [],
          },
        };
        currentPackage.value!.data.views = viewMap;
        currentViewKey.value = name;
      },
      removeView: (viewKey: string | number) => {


      }
    };
  },
});
