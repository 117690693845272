import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c971b3f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panels" }
const _hoisted_2 = { class: "panels-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "space-container",
    style: _normalizeStyle(_ctx.style)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workspace.spaces, (space) => {
      return (_openBlock(), _createElementBlock("div", {
        key: space,
        class: _normalizeClass(space.name),
        style: _normalizeStyle(_ctx.getStyle(space))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(space.panels, (panel) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "panel-container",
                key: panel
              }, [
                _renderSlot(_ctx.$slots, panel[1], {
                  class: _normalizeClass(panel.name)
                }, undefined, true)
              ]))
            }), 128))
          ])
        ])
      ], 6))
    }), 128))
  ], 4))
}