import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e1f0caa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "transform-editor" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = { class: "field has-addons" }
const _hoisted_5 = { class: "control is-expanded" }
const _hoisted_6 = { class: "control" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "field has-addons" }
const _hoisted_9 = { class: "control is-expanded" }
const _hoisted_10 = { class: "control" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "field has-addons" }
const _hoisted_13 = { class: "control" }
const _hoisted_14 = { class: "control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          class: "input is-small",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event))
        }, null, 512), [
          [_vModelText, _ctx.modelValue]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          class: "input is-small",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.srcPointsText) = $event)),
          placeholder: "Src points"
        }, null, 512), [
          [_vModelText, _ctx.srcPointsText]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("input", {
          class: "input is-small",
          readonly: "",
          value: _ctx.srcPoints,
          disabled: ""
        }, null, 8, _hoisted_7)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _withDirectives(_createElementVNode("input", {
          class: "input is-small",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dstPointsText) = $event)),
          placeholder: "Dest points"
        }, null, 512), [
          [_vModelText, _ctx.dstPointsText]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("input", {
          class: "input is-small",
          readonly: "",
          value: _ctx.dstPoints,
          disabled: ""
        }, null, 8, _hoisted_11)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("button", {
          class: "button is-small",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.calculate()))
        }, "Calculate")
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("button", {
          class: "button is-small",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickCalculate()))
        }, "Click Calculate")
      ])
    ])
  ]))
}