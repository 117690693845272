import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f89edfb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "line-configurations-editor-line" }
const _hoisted_2 = { class: "line-config-line-content" }
const _hoisted_3 = { class: "line-number" }
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "line-config-line-properties"
}
const _hoisted_6 = {
  key: 1,
  class: "simple-properties"
}
const _hoisted_7 = { class: "prop-label" }
const _hoisted_8 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StylesheetEditor = _resolveComponent("StylesheetEditor")!
  const _component_DimensionInput = _resolveComponent("DimensionInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.index), 1),
      _createElementVNode("input", {
        class: "input is-small line-content",
        disabled: "",
        value: _ctx.text
      }, null, 8, _hoisted_4)
    ]),
    (_ctx.internalConfig && _ctx.internalConfig.stylesheet)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.advancedMode)
            ? (_openBlock(), _createBlock(_component_StylesheetEditor, {
                key: 0,
                class: "editor",
                modelValue: _ctx.internalConfig.stylesheet,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalConfig.stylesheet) = $event))
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          (!_ctx.advancedMode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cssProperties, (prop, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "dimension-input-container",
                    key: i
                  }, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(prop), 1),
                    (prop === 'padding-left')
                      ? (_openBlock(), _createBlock(_component_DimensionInput, {
                          key: 0,
                          class: "dimension-input",
                          modelValue: _ctx.internalConfig.stylesheet[prop],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.internalConfig.stylesheet[prop]) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("", true),
                    (prop !== 'padding-left')
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 1,
                          class: "simple-input",
                          "onUpdate:modelValue": ($event: any) => ((_ctx.internalConfig.stylesheet[prop]) = $event)
                        }, null, 8, _hoisted_8)), [
                          [_vModelText, _ctx.internalConfig.stylesheet[prop]]
                        ])
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}