import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48d3f6f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "workspace" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArtboardPanel = _resolveComponent("ArtboardPanel")!
  const _component_ToolsPanel = _resolveComponent("ToolsPanel")!
  const _component_LayersPanel = _resolveComponent("LayersPanel")!
  const _component_PropertiesPanel = _resolveComponent("PropertiesPanel")!
  const _component_ProjectsPanel = _resolveComponent("ProjectsPanel")!
  const _component_ViewPanel = _resolveComponent("ViewPanel")!
  const _component_SnippetsPanel = _resolveComponent("SnippetsPanel")!
  const _component_PreviewPanel = _resolveComponent("PreviewPanel")!
  const _component_SchemaPanel = _resolveComponent("SchemaPanel")!
  const _component_ThemesPanel = _resolveComponent("ThemesPanel")!
  const _component_ExportPanel = _resolveComponent("ExportPanel")!
  const _component_ExportMultiplePanel = _resolveComponent("ExportMultiplePanel")!
  const _component_Workspace = _resolveComponent("Workspace")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workspacesWithoutExplorer, (w) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "tab",
          key: w
        }, [
          (_openBlock(), _createElementBlock("button", {
            onClick: ($event: any) => (_ctx.toggleSelect(w)),
            class: _normalizeClass({ active: _ctx.isActive(w) }),
            key: _ctx.activeWorkspace
          }, _toDisplayString(w.name), 11, _hoisted_2))
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Workspace, { workspace: _ctx.activeWorkspace }, {
        artboardPanel: _withCtx(() => [
          (_ctx.pkgData)
            ? (_openBlock(), _createBlock(_component_ArtboardPanel, {
                key: 0,
                spread: _ctx.spread,
                ref: "artboardViewerComponent",
                runArguments: _ctx.pickedPreviewArgument && _ctx.pickedPreviewArgument.arguments,
                snippets: _ctx.pkgData && _ctx.pkgData.snippets,
                themes: _ctx.pkgData && _ctx.pkgData.themes,
                fonts: _ctx.pkgData && _ctx.pkgData.fonts,
                externalSpreadRegistry: _ctx.externalSpreadRegistry,
                maximumQuality: _ctx.pkgData && _ctx.pkgData.previewSettings.maximumQuality,
                transfoSvgPredownloader: _ctx.transfoSvgPredownloader
              }, null, 8, ["spread", "runArguments", "snippets", "themes", "fonts", "externalSpreadRegistry", "maximumQuality", "transfoSvgPredownloader"]))
            : _createCommentVNode("", true)
        ]),
        toolsPanel: _withCtx(() => [
          _createVNode(_component_ToolsPanel, { spread: _ctx.spread }, null, 8, ["spread"])
        ]),
        layersPanel: _withCtx(() => [
          _createVNode(_component_LayersPanel, {
            spread: _ctx.spread,
            selection: _ctx.selectedThing,
            selectionType: _ctx.selectedThingType,
            onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selected($event)))
          }, null, 8, ["spread", "selection", "selectionType"])
        ]),
        propertiesPanel: _withCtx(() => [
          _createVNode(_component_PropertiesPanel, {
            selection: _ctx.selectedThing,
            selectionType: _ctx.selectedThingType,
            pkgData: _ctx.pkgData
          }, null, 8, ["selection", "selectionType", "pkgData"])
        ]),
        projectsPanel: _withCtx(() => [
          _createVNode(_component_ProjectsPanel, {
            "onUpdate:activeWorkspace": _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleSelect(_ctx.workspaces[1])))
          })
        ]),
        viewPanel: _withCtx(() => [
          _createVNode(_component_ViewPanel, {
            "onUpdate:spread": _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectSpread($event))),
            "onUpdate:viewKey": _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectView($event))),
            onPlay: _cache[4] || (_cache[4] = ($event: any) => (_ctx.play())),
            onToggleLive: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleLive())),
            live: _ctx.live,
            pickedPreviewArgument: _ctx.pickedPreviewArgument,
            "onUpdate:pickedPreviewArgument": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pickedPreviewArgument) = $event)),
            liveInterval: _ctx.liveInterval,
            "onUpdate:liveInterval": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.liveInterval) = $event)),
            currentSpread: _ctx.spread,
            currentViewKey: _ctx.selectedViewKey,
            rendering: _ctx.rendering
          }, null, 8, ["live", "pickedPreviewArgument", "liveInterval", "currentSpread", "currentViewKey", "rendering"])
        ]),
        snippetsPanel: _withCtx(() => [
          _createVNode(_component_SnippetsPanel, { pkgData: _ctx.pkgData }, null, 8, ["pkgData"])
        ]),
        previewPanel: _withCtx(() => [
          _createVNode(_component_PreviewPanel, {
            pkg: _ctx.currentPackage,
            pkgData: _ctx.pkgData,
            pickedArgument: _ctx.pickedPreviewArgument,
            "onUpdate:pickedArgument": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pickedPreviewArgument) = $event))
          }, null, 8, ["pkg", "pkgData", "pickedArgument"])
        ]),
        schemaPanel: _withCtx(() => [
          _createVNode(_component_SchemaPanel, { pkgData: _ctx.pkgData }, null, 8, ["pkgData"])
        ]),
        themesPanel: _withCtx(() => [
          _createVNode(_component_ThemesPanel, { pkgData: _ctx.pkgData }, null, 8, ["pkgData"])
        ]),
        exportPanel: _withCtx(() => [
          _createVNode(_component_ExportPanel, {
            pkg: _ctx.currentPackage,
            viewKey: _ctx.selectedViewKey,
            previewArgument: _ctx.pickedPreviewArgument
          }, null, 8, ["pkg", "viewKey", "previewArgument"])
        ]),
        exportMultiplePanel: _withCtx(() => [
          _createVNode(_component_ExportMultiplePanel, {
            pkg: _ctx.currentPackage,
            viewKey: _ctx.selectedViewKey
          }, null, 8, ["pkg", "viewKey"])
        ]),
        _: 1
      }, 8, ["workspace"])
    ])
  ], 64))
}