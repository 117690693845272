
import { defineComponent, computed, ref, watch } from "vue";
import { Package, Project, PackageData, PreviewArgument } from "@bubblydoo/common";
import Panel from "../panel-general/Panel.vue";
import PanelSection from "../panel-general/PanelSection.vue";
import PanelAttribute from "../panel-general/PanelAttribute.vue";
import JsonEditor from "../../inputs/JsonEditor.vue";

import { PROCESSOR_WS_URL, TRANSFO_API_URL, STORE_BASE_URL } from "@/config";
import cloneDeep from "lodash/cloneDeep";
import ExportRenderContainer from "@/components/display/ExportRenderContainer.vue";
import SelectionList from "@/components/inputs/SelectionList.vue";

export default defineComponent({
  props: [
    "pkg",
    "viewKey"
  ],
  components: {
    Panel,
    PanelAttribute,
    ExportRenderContainer,
    SelectionList
  },
  setup(props, { emit }) {
    console.log(props)
    const viewKey = computed(() => props.viewKey);
    const pkg = computed(() => props.pkg as Package);
    const previewArguments = ref<PreviewArgument[]>(props.pkg.data.previewArguments);
    const selectedArguments = ref<PreviewArgument[]>([])
    const messages = ref<any[]>([]);
    const croppings = ref<string[]>([]);
    const exporting = ref(false);
    const exportStuff = ref({
      target: "pdf",
      maximumQuality: "300",
      fileFormat: "png",
      transparentBackground: false,
      processorWebsocketUrl: PROCESSOR_WS_URL,
      transfoStoreUrl: TRANSFO_API_URL
    });

    return {
      pkg,
      previewArguments,
      exportStuff,
      messages,
      croppings,
      viewKey,
      STORE_BASE_URL,
      selectedArguments, exporting,
      renderView: [viewKey.value],
      optionName: (option: PreviewArgument) => option.name,
      changeSelected: (selected: PreviewArgument[]) => {
        console.log("lol",selected)
        selectedArguments.value = selected;
      },
      async runExport() {
        if (exporting.value){
          const refreshConfirmation = confirm("Are you sure you would like to refresh?")
          if (refreshConfirmation) { console.log('Refreshing') }
        }
        exporting.value = true;
      },
    };
  },
});
