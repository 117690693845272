
import { e } from "mathjs";
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";


interface Props<T> {
    options: T[];
    listName: string;
    optionName: (option: T) => string;
}

export default defineComponent({
    props: ["options", "optionName", "listName"],
    components: {},
    setup(props: Props<unknown>, { emit }) {
        const options = computed(() => props.options)
        console.log("options:", options.value)
        const listName = computed(() => props.listName)
        const optionName = (option: unknown) => props.optionName(option)
        const selectedOptions = ref<unknown[]>([])
        const removeArgs = (removeOption: unknown) => {
            selectedOptions.value = selectedOptions.value.filter((option) => option !== removeOption)
            emit("changeSelected", selectedOptions.value)
        }
        const addArgs = (option: unknown) => {
            selectedOptions.value = selectedOptions.value.concat([option])
            emit("changeSelected", selectedOptions.value)
        }
        return {
            options, optionName, listName, selectedOptions, removeArgs, addArgs
        }
    }
})
