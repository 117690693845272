
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    title: String,
    hasFooter: Boolean,
    overflow: String,
  },
  setup(props) {
    const title = computed(() => props.title);
    const overflow = computed(() => props.overflow || "auto");

    return {
      title,
      overflow,
    };
  },
});
