
import { defineComponent, computed, ref, watch } from "vue";
import type { Package, PackageData, PreviewArgument } from "@bubblydoo/common";
import Panel from "../panel-general/Panel.vue";
import PanelSection from "../panel-general/PanelSection.vue";
import PanelAttribute from "../panel-general/PanelAttribute.vue";
import JsonEditor from "../../inputs/JsonEditor.vue";

import { PROCESSOR_WS_URL, TRANSFO_API_URL, STORE_BASE_URL } from "@/config";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  props: {
    pkg: Object,
    viewKey: String,
    previewArgument: Object,
  },
  components: {
    Panel,
    PanelAttribute,
  },
  setup(props, { emit }) {
    const viewKey = computed(() => props.viewKey);
    const pkg = computed(() => props.pkg as Package);
    const previewArgument = computed(() => props.previewArgument as PreviewArgument);
    console.log("previewArgument", previewArgument.value);
    const messages = ref<any[]>([]);
    const croppings = ref<string[]>([]);

    const exportStuff = ref({
      target: "pdf",
      maximumQuality: "300",
      fileFormat: "png",
      transparentBackground: false,
      processorWebsocketUrl: PROCESSOR_WS_URL,
      transfoStoreUrl: TRANSFO_API_URL
    });

    return {
      pkg,
      previewArgument,
      exportStuff,
      messages,
      croppings,
      viewKey,
      STORE_BASE_URL,
      async runExport() {
        const socket = new WebSocket(exportStuff.value.processorWebsocketUrl);
        messages.value = [];

        // export interface CaptureSequenceInstruction {
        //   captures: {
        //     view: string;
        //     spreads: number[] | "*";
        //     croppings?: string[];
        //   }[];
        //   target: Target;
        //   rendererFrontendUrl?: string;
        //   notifications?: {
        //     webhooks?: {

        //     },
        //     websockets?: {
        //       ignoreGone?: boolean
        //     }
        //   }
        // }

        socket.onopen = () => {
          console.log("Websocket connected");
          socket.send(
            JSON.stringify({
              action: "process",
              request: JSON.stringify({
                packageId: pkg.value.id,
                runArguments: previewArgument.value.arguments,
                transfoStoreUrl: exportStuff.value.transfoStoreUrl,
                projectsStoreUrl: STORE_BASE_URL,
                instruction: {
                  captures: [
                    {
                      view: viewKey.value,
                      spreads: "*",
                      croppings: croppings.value.length ? croppings.value : undefined,
                    },
                  ],
                  target: {
                    type: exportStuff.value.target,
                    options: {
                      fileFormat: exportStuff.value.fileFormat,
                    },
                    consolidation: {
                      level: exportStuff.value.target === "pdf" ? "view" : undefined,
                    },
                  },
                  notifications: {
                    websocket: {
                      enabled: true,
                      throwOnGone: true,
                    },
                  },
                  derivativeMaximumQuality: exportStuff.value.maximumQuality,
                  parallelization: "all",
                },
              }),
            })
          );
        };

        socket.onmessage = (event) => {
          console.log(event);
          const data = JSON.parse(event.data);
          console.log(data);
          messages.value.push(data);
        };
      },
    };
  },
});
