
import { defineComponent, ref, watch, computed, onMounted, unref } from "vue";
import { Package, Project, PackageData, PreviewArgument } from "@bubblydoo/common";
import MonacoWrapper, { monacoSettings } from "../MonacoWrapper.vue";
import ConfirmingButton from "./ConfirmingButton.vue";
import { QueueElement } from "./ExportRenderContainer.vue";

const DEFAULT_JSON_STRING = "{}";

export default defineComponent({
  props: {
    queueElement: Object
  },
  setup(props, { emit }) {
    const open = ref(false);
    const queueElement = computed(() => props.queueElement as QueueElement);
    const percentage = computed(() => Math.round(queueElement.value.completionPercentage * 100) / 100);
    const color = computed(() => {
      var r, g, b = 0;
      const perc = percentage.value;
      if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
      }
      else {
        g = 255;
        r = Math.round(510 - 5.10 * perc);
      }
      var h = r * 0x10000 + g * 0x100 + b * 0x1;
      return '#' + ('000000' + h.toString(16)).slice(-6);
    });
    const width = computed(() => percentage.value + "%");
    const target = computed(() => queueElement.value.generationOptions.type);
    return {
      queueElement,
      open,
      color, percentage,
      width, target
    };
  },
});
