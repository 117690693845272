import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a17447e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "croppings-editor" }
const _hoisted_2 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JsonEditor = _resolveComponent("JsonEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.internalCroppings || [], (cropping, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "cropping",
        key: i
      }, [
        _withDirectives(_createElementVNode("input", {
          class: "input is-small",
          "onUpdate:modelValue": ($event: any) => ((cropping.name) = $event)
        }, null, 8, _hoisted_2), [
          [_vModelText, cropping.name]
        ]),
        _createVNode(_component_JsonEditor, {
          modelValue: cropping.dimensions,
          "onUpdate:modelValue": ($event: any) => ((cropping.dimensions) = $event)
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ]))
    }), 128)),
    _createElementVNode("button", {
      class: "button is-small",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addCropping()))
    }, "Add cropping")
  ]))
}