
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "@/store/store";
import { Spread, Layer, Stylesheet, TextLayer, Package, PackageData, View } from "@bubblydoo/common";
import Panel from "../panel-general/Panel.vue";
import PanelSection from "../panel-general/PanelSection.vue";
import PanelAttribute from "../panel-general/PanelAttribute.vue";
import DimensionInput from "../../inputs/DimensionInput.vue";
import SpacingInput from "../../inputs/SpacingInput.vue";
import FormatInput from "../../inputs/FormatInput.vue";
import JsonEditor from "../../inputs/JsonEditor.vue";
import StylesheetEditor from "../../inputs/StylesheetEditor.vue";
import MonacoWrapper from "../../MonacoWrapper.vue";
import CroppingsEditor from "../../inputs/CroppingsEditor.vue";
import PreserveAspectRatioInput from "../../inputs/PreserveAspectRatioInput.vue";
import LineConfigurationsEditor from "../../inputs/LineConfigurationsEditor.vue";
import TransfoPackageInput from "../../inputs/TransfoPackageInput.vue";
import TransformEditor from "../../inputs/TransformEditor.vue";
import { assign } from "lodash";

export default defineComponent({
  props: {
    selectionType: String, // book | spread | layer
    selection: Object,
    pkgData: Object,
  },
  components: {
    DimensionInput,
    SpacingInput,
    FormatInput,
    PreserveAspectRatioInput,
    JsonEditor,
    StylesheetEditor,
    MonacoWrapper,
    LineConfigurationsEditor,
    CroppingsEditor,
    TransfoPackageInput,
    TransformEditor,
    Panel,
    PanelSection,
    PanelAttribute,
  },
  setup(props) {
    const store = useStore();

    const selection = computed<Package | View | Spread | Layer>(() => props.selection as any);
    const selectionType = computed<"book" | "view" | "spread" | "layer" | null>(
      () => props.selectionType as any
    );
    const pkgData = computed(() => props.pkgData as PackageData);

    const enforceNBSP = ref<boolean>(false);

    // Ensure the existence of some properties
    watch(selection, (sel) => {
      if (selectionType.value === "layer") {
        const layer = selection.value as Layer;
        if (!layer.styling) layer.styling = {};
        if (!layer.styling.stylesheet) layer.styling.stylesheet = {};
      }
    });

    const newTheme = ref<string>();

    return {
      selection,
      selectionType,
      isSelectionLayer: computed(() => {
        return selectionType.value === "layer" && selection.value;
      }),
      isSelectionSpread: computed(() => {
        return selectionType.value === "spread" && selection.value;
      }),
      isSelectionBook: computed(() => {
        return selectionType.value === "book" && selection.value;
      }),
      isSelectionView: computed(() => {
        return selectionType.value === "view" && selection.value;
      }),
      isSelectionLayerWithType(type: string | string[]) {
        if (selectionType.value !== "layer") return false;
        const layer = selection.value as Layer;
        return layer && (typeof type === "string" ? [type] : type).includes(layer.type);
      },
      stylingAdvancedMode: ref(true),
      newTheme,
      enforceNBSP,
      addTheme() {
        if (!("styling" in selection.value) || !newTheme.value) return;
        const styling = selection.value.styling!;
        styling.themes = styling.themes || [];
        if (styling.themes.includes(newTheme.value!)) return;
        styling.themes = [...styling.themes, newTheme.value!];
        newTheme.value = undefined;
      },
      removeTheme(theme: string) {
        if (!confirm('Remove theme?')) return;
        if (!("styling" in selection.value)) return;
        const styling = selection.value.styling!;
        if (!styling.themes) return;
        styling.themes.splice(
          styling.themes.findIndex((x: string) => x === theme),
          1
        );
      },
      availableThemes: computed(() => {
        if (!pkgData.value) return;
        if (!("styling" in selection.value)) return;
        const styling = selection.value.styling!;
        styling.themes = styling.themes || [];
        return pkgData.value.themes.filter((t) => !styling.themes!.includes(t.name));
      }),
      setRaw: (event: any) => {
        if (selectionType.value === "layer") {
          assign(selection.value, event);
        } else if (selectionType.value === "spread") {
          assign(selection.value, event);
        } else if (selectionType.value === "book") {
          assign((selection.value as Package).data, event);
        } else if (selectionType.value === "view") {
          assign(selection.value as View, event);
        }
      },
    };
  },
});
