
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import semverSort from "semver/functions/sort";
import { Project, Package } from "@bubblydoo/common";

const formatDate = new Intl.DateTimeFormat('en-GB', { dateStyle: 'long', timeStyle: 'short' })

export default defineComponent({
  props: ["project"],
  setup(props, { emit }) {
    const project = computed(() => props.project as Project);


    const sortedPackages = computed(() => {
      const sortedPackages = [...project.value.packages!].sort((a, b) =>
        semverSort([a.version, b.version])[0] === a.version ? -1 : 1
      );
      return sortedPackages;
    });

    const sortedPackagesAndSelector = computed(() => {
      const sortedPackagesAndSelector = sortedPackages.value.map((pkg) => ({ pkg, version: pkg.version, tags: [] as string[] }));
      Object.entries(project.value.tags || {}).forEach(([tagName, pkgId]) => {
        const { tags } = sortedPackagesAndSelector.find(({ pkg }) => pkg.id === pkgId)!;
        tags.push(tagName);
      });
      return sortedPackagesAndSelector;
    });

    const latestPackageAndSelectorTags = computed(() => {
      const sp = sortedPackagesAndSelector.value
      return sp[sp.length - 1].tags
    })

    const pkg = ref<Package>();


    const updateTime = ref<string>()

    const selectAsDuplicate = async () => {
      emit("selectToDuplicate", { projectName: project.value.name, packageId: pkg.value?.id, version: pkg.value?.version })
    }

    const toAddTag = ref<string | null>(null);

    watch(sortedPackages, () => selectLatestPackage());

    watch(pkg, () => {
      if (pkg.value)
        updateTime.value = formatDate.format(new Date(pkg.value?.createdAt!))
    });

    watch(toAddTag, (tag) => {
      if (pkg.value && tag) {
        if (tag == 'custom') {
          const customTag = prompt("Tag name");
          if (!customTag) return;
          emit("addProjectTag", { packageId: pkg.value.id, tagName: customTag });
        }
        else
          emit("addProjectTag", { packageId: pkg.value.id, tagName: tag });
        toAddTag.value = null
      }
    });
    onMounted(() => selectLatestPackage());

    const selectLatestPackage = () => {
      const sp = sortedPackages.value;
      pkg.value = sp[sp.length - 1];

    };


    return {
      sortedPackagesAndSelector,
      latestPackageAndSelectorTags,
      pkg,
      toAddTag,

      updateTime,
      load() {

        if (pkg.value) emit("loadPkg", pkg.value);
        else emit("addPkg");
      },
      rename() {
        if (project.value) {
          const name = prompt("New name");
          if (!name) return;
          emit("updateProject", { ...project.value, name });
        }
      },
      copy(str: string) {
        navigator.clipboard.writeText(str);
      },
      selectAsDuplicate,
      formatPkgDate: (date: Date) => formatDate.format(date),
    };
  },
});
