import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JsonEditor = _resolveComponent("JsonEditor")!
  const _component_PanelAttribute = _resolveComponent("PanelAttribute")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { title: "Schema" }, {
    default: _withCtx(() => [
      (_ctx.pkgData)
        ? (_openBlock(), _createBlock(_component_PanelAttribute, {
            key: 0,
            title: "Schema",
            expandable: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_JsonEditor, {
                modelValue: _ctx.pkgData.schema,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pkgData.schema) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}