import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f606366c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          class: "input border-radius-right border-radius-left double-width",
          placeholder: "Value",
          spellcheck: "false",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event))
        }, null, 512), [
          [
            _vModelText,
            _ctx.internalValue,
            void 0,
            { trim: true }
          ]
        ])
      ])
    ])
  ]))
}