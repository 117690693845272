import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e23360a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selectedInputsContainer" }
const _hoisted_2 = { class: "section" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "section" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "content" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, "Selected " + _toDisplayString(_ctx.listName), 1),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, " Selected " + _toDisplayString(_ctx.listName), 1),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOptions, (option, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              (_ctx.selectedOptions.includes(option))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "argument",
                    onClick: ($event: any) => (_ctx.removeArgs(option))
                  }, _toDisplayString(_ctx.optionName(option)), 9, _hoisted_5))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, " Available " + _toDisplayString(_ctx.listName), 1),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              (!_ctx.selectedOptions.includes(option))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "argument",
                    onClick: ($event: any) => (_ctx.addArgs(option))
                  }, _toDisplayString(_ctx.optionName(option)), 9, _hoisted_9))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ])
  ], 64))
}